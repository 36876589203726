<template>
  <div class="position-relative w-100">
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold header-main text-uppercase mb-3">
              รายละเอียดสินค้า
            </h1>
          </b-col>
        </b-row>

        <b-tabs class="mt-3 product-tabs">
          <b-tab :title="'รายละเอียดสินค้า'" active>
            <ProductDetailsSection @updateProductTypeId="(typeId) => productTypeId = typeId" />
          </b-tab>
          <b-tab :title="'จัดการคำบรรยายสินค้า'">
            <ProductDescriptions />            
          </b-tab>
          <!-- <b-tab :title="$t('inventory')">
              <ProductStock />
            </b-tab>
            <b-tab :title="$t('question')">
              <ProductQuestionSection />
            </b-tab>
            <b-tab :title="$t('userReview')">
              <ProductUserReviewSection />
            </b-tab> -->
        </b-tabs>
      </b-container>
    </form>
  </div>
</template>

<script>
import ProductDetailsSection from "@/views/pages/product/components/ProductDetailSection";
import ProductDescriptions from "@/views/pages/product/components/ProductDescriptions";

export default {
  name: "ProductDetails",
  components: {
    ProductDetailsSection,
    ProductDescriptions
  },
  data() {
    return {
      productTypeId: 0
    };
  },
  created: async function() {},
  methods: {},
};
</script>

<style scoped></style>
